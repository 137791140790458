import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import tw from 'twin.macro';

import WideLogo from '../svgs/WideLogo';
import { ImageTextRow } from '../components/ImageTextRow/';
import PageLayout from '../components/PageLayout/';
import SEO from '../components/seo';
import { Hero } from '../components/Hero/';
import CompanyTestimonials from '../components/CompanyTestimonials/';
import Section from '../components/Section/';
import { Testimonials } from '../components/Testimonials/';
import Advantage, { AdvantageRow } from '../components/Advantage/';

import getEnvVars from '../environment';
import FAQAccordion from '../components/FAQAccordion/';
import { Press } from '../components/Press/';
import {
  Nav,
  Wrapper,
  VideoSection,
  VideoContainer,
  VideoWrapper,
  LogoContainer,
  DropPointSection,
  DropPointHeader,
  RegisterLink,
  AdvantageHeader,
  AdvantageContent,
  RegisterLinkContainer,
  FAQSection,
  FAQWrapper,
  FAQHeader,
  FAQSubHeader,
  BiSearchAltIcon,
  AiOutlineCheckCircleIcon,
  VscRocketIcon,
  ImStatsBars2Icon,
  HiStarIcon,
  RegisterLinkContainerAdvantages,
} from '../styles/paketshop-werden';
import { Helmet } from 'react-helmet';
import { useTracking } from '../hooks/useTracking';
import LPFooter from '../components/Footer/LPFooter';

const { webAppUrl } = getEnvVars();

const ErfolgreichMitPaketshops = ({
  data: {
    Hero1,
    Hero2,
    Hero3,
    Hero4,
    Hero5,
    ImageGutschein,
    ImagePakete,
    ImageKleiderstange,
    Business3,
    Business4,
    Sprint3,
  },
}) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const { trackEvent } = useTracking();
  const images = [Hero1, Hero2, Hero3, Hero4, Hero5];

  const handleScroll = () => {
    const scrollpos = window.scrollY;
    const toToggle = document.querySelectorAll('.toggleColour');

    if (scrollpos > 10) {
      setIsScrolling(true);
      //Use to switch toggleColour colours
      for (let i = 0; i < toToggle.length; i++) {
        toToggle[i].classList.add('text-gray-800');
        toToggle[i].classList.remove('text-white');
      }
    } else {
      setIsScrolling(false);
      //Use to switch toggleColour colours
      for (let i = 0; i < toToggle.length; i++) {
        toToggle[i].classList.add('text-white');
        toToggle[i].classList.remove('text-gray-800');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  });

  return (
    <PageLayout hideNav hideFooter>
      <Nav isScrolling={isScrolling} id="navigation">
        <Wrapper>
          <LogoContainer>
            <WideLogo isScrolling={isScrolling} />
          </LogoContainer>
        </Wrapper>
      </Nav>
      <SEO title="Unsere Angebote für Dein Unternehmen" />
      <Helmet>
        <script>
          {`
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3054199,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </script>
      </Helmet>
      <Hero images={images} fullscreen hideCardTitle showCalculator />
      <CompanyTestimonials />

      <VideoSection className="py-8 md:py-16 bg-white">
        <div className="w-3/4 flex justify-center">
          <VideoWrapper>
            <VideoContainer>
              <iframe
                // width="2032"
                // height="400"
                src="https://www.youtube.com/embed/UUNqN0k9c68"
                title="Video über DropFriends"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </VideoContainer>
          </VideoWrapper>
        </div>
      </VideoSection>

      <FAQSection>
        <FAQWrapper>
          <div className="text-center mb-12">
            <FAQHeader>FAQ</FAQHeader>
            <FAQSubHeader>Eure Fragen. Unsere Antworten.</FAQSubHeader>
          </div>
          <FAQAccordion headerBg="bg-white" question="Was ist ein DropPoint?">
            <p className="mb-4">
              DropPoints sind Annahmestellen von Paketsendungen, die z.B. die Einwohner Deines
              Stadtviertels wegen Arbeitszeiten oder Terminen nicht selbst entgegennehmen können.
            </p>
            <p className="mb-4">
              Mit Hilfe von DropPopints sind Abholzeiten bereits vor der Bestellung von
              Online-Einkäufen geregelt. So können Sendungseigentümer ihren Wunsch-DropPoint ganz
              nach ihren Bedürfnissen und Alltagsplänen auswählen. Das spart Zeit, ist
              umweltfreundlich und sichert die Erstzustellung von Sendungen.
            </p>
            <p>
              Somit entscheidet nicht mehr der Paketbote was mit dem Eigentum Anderer geschieht. Das
              ist nur fair.
            </p>
          </FAQAccordion>
          <FAQAccordion headerBg="bg-white" question="Wie viele Sendungen muss ich annehmen?">
            <p className="mb-4">
              Egal ob Du nur eine Sendung aufbewahren magst oder Dich die Sendungsanzahl nicht
              weiter interessiert. Bei DropFriends hast Du die freie Wahl.
            </p>
            <p className="mb-4">
              Und ist Deine angegebene Kapazität tatsächlich einmal ausgeschöpft, sind automatisch
              keine weiteren Buchungen Deines DropPoints mehr möglich, bis mindestens eine der
              Sendungen erfolgreich an den Eigentümer übergeben worden ist.
            </p>
            <p>So stellt DropFriends die Einhaltung Deiner Wünsche sicher.</p>
          </FAQAccordion>
          <FAQAccordion
            headerBg="bg-white"
            question="Wann kommt man denn die Pakete bei mir abholen?">
            <p className="mb-4">
              Als DropPoint steht es Dir frei selbst zu bestimmen wann Du Sendungen an Deine
              DropFriends übergeben möchtest. So kannst Du über die Nutzung Deiner Öffnungszeiten
              frei bestimmen und man wird als Sendungseigentümer bereits vor der Buchung Deines
              DropPoints über die möglichen Abholzeiträume informiert.
            </p>
            <p>
              Sobald also Pakete zu den gewohnten Lieferzeiträumen durch die Paketdienstleister bei
              Dir übergeben worden sind, erhalten die Sendungseigentümer eine
              Echtzeit-Benachrichtigung und wissen bescheid, dass sie ihre Bestellungen zu den von
              Dir angegebenen Abholzeiten übergeben bekommen können.
            </p>
          </FAQAccordion>
          <FAQAccordion headerBg="bg-white" question="Wie läuft das mit den Gutscheinen?">
            <p className="mb-4">
              Nach Deiner Registrierung kannst Du Dein Ladenlokal mit wenigen Klicks als
              Paketannahmestelle aufschalten. Hast Du bereits mehrere Filialen, dann kannst Du
              selbstverständlich auch diese als DropPoint aktivieren.
            </p>
            <p className="mb-4">
              Pro Filiale bzw. Ladenlokal kannst Du einen Gutschein erstellen und frei bestimmen
              welche Form des Mehrwertes Du bei einer Paketabholung triggern möchtest. Egal ob z.B.
              eine Vergünstigung von 5% an der Kasse berücksichtigt werden soll oder Du beim Einkauf
              einen kostenlosen Kaffee verschenken magst – Deiner Kreativität sind keine Grenzen
              gesetzt. Ganz einfach mit wenigen Klicks.
            </p>
            <p>
              Nachdem man Pakete bei Dir im Ladenlokal überreicht bekommen hat, steht der Gutschein
              in der App sofort zum Abruf bereit – noch während die Kunden bei Dir im Laden sind:
              Einfach bei Dir an der Kasse vorzeigen und mit einem Fingerwisch in der App einlösen.
              Fertig. Dein Kassenpersonal kann den Rabatt an der Kasse direkt für den Einkauf
              verrechnen. Einfach, sicher und in Echtzeit.
            </p>
          </FAQAccordion>
          <RegisterLinkContainer tw={'justify-center mt-12'}>
            <RegisterLink
              tw={'text-gray-800 hover:bg-gray-200 bg-white'}
              href={`${webAppUrl}signup?companyType=business`}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                trackEvent('trackCustom', 'InitiateB2BSignup');
              }}>
              Jetzt profitieren
            </RegisterLink>
          </RegisterLinkContainer>
        </FAQWrapper>
      </FAQSection>

      <AdvantageRow
        title="Dein Umsatz-Boost"
        subTitle="Dein Ladenlokal startet durch"
        titleId="vorteile"
        className="justify-center">
        <Advantage icon={<ImStatsBars2Icon />} title="Erhöhen der Laufkundschaft ">
          <p>
            Sie sind der relevante Ort, zur relevanten Zeit mit dem relevanten Angebot. Die
            Nachbarschaft wünscht ab sofort gezielt eine Paketabholung in Ihrem Ladenlokal. Ganz
            egal von welchem Paketdienstleister! Sie nutzen mit DropFriends die Potenziale der
            Bevölkerungsdichte Ihres Viertels.
          </p>
        </Advantage>
        <Advantage icon={<VscRocketIcon />} title="Steigender Umsatz">
          <p>
            Im Gegensatz zu gewöhnlichen Paket-Shops animieren wir bei der Paketabholung zum
            Einkauf! Ein innovatives Coupon-System spielt von Ihnen selbst erstellte Gutscheine per
            App aus: In Echtzeit! Die Einlösung kann dann noch vor Ort erfolgen; z. B. ein 10 %
            Rabatt. Echter Umsatz statt Cent-Beträge pro Paket.
          </p>
        </Advantage>
        <Advantage icon={<AiOutlineCheckCircleIcon />} title="Mehr Flexibilität ">
          <p>
            Alle Paketdienstleister unter Ihrem Dach. Sie bestimmen über die mögliche Anzahl von
            Paketen und derer maximalen Größe. Eine Überbuchung Ihrer Flächen findet nicht statt.
            Das Anpassen Ihrer Annahmekriterien ist jederzeit möglich; inklusive Ihrer individuellen
            Abholzeiten fürs Stadtviertel.
          </p>
        </Advantage>
        <Advantage icon={<BiSearchAltIcon />} title="Hohe Sichtbarkeit">
          <p>
            Ihr Geschäft präsentiert sich digital auf unserer Plattform. Dies erhöht die
            Sichtbarkeit Ihres Ladenlokals für die wirklich relevanten lokalen Zielgruppen. Sie
            erhalten durch Ihr mehrwertiges Angebot eine konsumbereite und wiederkehrende
            Neukundschaft.
          </p>
        </Advantage>
      </AdvantageRow>

      <DropPointSection fullWidth>
        <DropPointHeader>Bereits 1500+ unabhängige DropPoints</DropPointHeader>
        <RegisterLinkContainer tw={'justify-center m-0'}>
          <RegisterLink
            href={`${webAppUrl}signup?companyType=business`}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              trackEvent('trackCustom', 'InitiateB2BSignup');
            }}>
            Jetzt registrieren
          </RegisterLink>
        </RegisterLinkContainer>
      </DropPointSection>

      <Press />

      <Section title="Warum DropPoint werden?" fullWidth>
        <ImageTextRow leftImageFluid={ImageGutschein} position="left" fullWidth>
          <AdvantageHeader>Umsatz steigern</AdvantageHeader>
          <AdvantageContent>
            Nur mit der Annahme von Paketen und individuellen Gutscheinen, die Du selbst erstellst
            und bearbeiten kannst. Jederzeit. Du gibst Deinen Kunden so mehrere Gründe bei Dir
            reinzuschneien und Deine Kasse aufzubessern.
          </AdvantageContent>
        </ImageTextRow>
        <ImageTextRow rightImageFluid={ImagePakete} position="right" fullWidth>
          <AdvantageHeader>Deine Spielregeln</AdvantageHeader>
          <AdvantageContent>
            Bestimme die Anzahl, die Größe und das Gewicht der Paketsendungen die Du annehmen
            möchtest. Vor einer Buchung wird man über Deine Regeln informiert und die Sicherstellung
            funktioniert vollautomatisch.
          </AdvantageContent>
          <RegisterLinkContainerAdvantages>
            <RegisterLink
              href={`${webAppUrl}signup?companyType=business`}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                trackEvent('trackCustom', 'InitiateB2BSignup');
              }}>
              Jetzt registrieren
            </RegisterLink>
          </RegisterLinkContainerAdvantages>
        </ImageTextRow>
        <ImageTextRow leftImageFluid={Business3} position="left" fullWidth>
          <AdvantageHeader>Sicher ist sicher</AdvantageHeader>
          <AdvantageContent>
            Paketübergaben erfolgen nur nach erfolgreicher Identifizierung: Bestimme selbst ob per
            Fotoabgleich oder Prüfung des Ausweises. Bereits vor der Buchung wird man über die
            Prüfungsart informiert. So werden Wartezeiten bei der Übergabe vermieden.
          </AdvantageContent>
        </ImageTextRow>
        <ImageTextRow rightImageFluid={Sprint3} position="right" fullWidth>
          <AdvantageHeader>Der Umwelt zuliebe</AdvantageHeader>
          <AdvantageContent>
            Als DropPoint bist Du die zentrale Anlaufstelle für alle Boten. Heißt: Du bist nicht auf
            eine Lieferdienst-Marke beschränkt. Mehrere Zustellversuche durch Paketdienstleister
            werden durch die Buchung Deines DropPoints reduziert. Das verringert den CO2-Ausstoß pro
            Paket auf bis zu 277g. Die monatliche Gesamteinsparung errechnen wir Dir automatisch im
            DropFriends-Dashboard, damit Du Deine Kunden mit einer echten Kennzahl über Dein
            Engagement zum Umweltschutz informieren kannst.
          </AdvantageContent>
          <RegisterLinkContainerAdvantages>
            <RegisterLink
              href={`${webAppUrl}signup?companyType=business`}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                trackEvent('trackCustom', 'InitiateB2BSignup');
              }}>
              Jetzt registrieren
            </RegisterLink>
          </RegisterLinkContainerAdvantages>
        </ImageTextRow>

        <ImageTextRow
          rightImageFluid={Business4}
          // rightImageMobileLast
          leftImageFluid={ImageKleiderstange}
          fullWidth
        />
      </Section>

      <Testimonials
        className="bg-gray-200"
        testimonials={[
          {
            testimonial: (
              <>
                Funktioniert!
                <br />
                In Köln gibts enorm viele Annahmestellen und ich habe meinen gefunden. Probiert‘s
                aus!
              </>
            ),
            name: 'Katrin',
            source: 'Play Store',
          },
          {
            testimonial:
              'Ich kann die App wärmstens empfehlen. Bisher hat alles super geklappt und es kommen dauernd neue Orte hinzu, wo ich was hin liefern kann. Nach dem ganzen Stress, den ich bisher in meinem Leben mit Packstationen hatte, bin ich echt erleichtert, dass sich jemand um eine bessere Lösung bemüht hat.',
            name: 'Dennis ',
            source: 'Play Store',
          },
          {
            testimonial: (
              <>
                Super!
                <br />
                Dank der App fühle ich mich bzw. meine Pakete gut aufgehoben. Das hier braucht jeder
                und mein Gott, wie einfach alles war. Einfach genial!!!
              </>
            ),
            name: 'Engin ',
            source: 'Play Store',
          },
          {
            testimonial: (
              <>
                Super App!
                <br />
                Läuft stabil und tut was sie soll.
              </>
            ),
            name: 'Chris ',
            source: 'Play Store',
          },
          {
            testimonial:
              'Finde, das ist eine sehr clevere App, viel Potenzial ist da! Ich habe schon einen DropPoint aufgemacht. Finde die Idee super!',

            name: 'Phillip ',
            source: 'Play Store',
          },
          {
            testimonial: (
              <>
                Super App!
                <br />
                Unbedingt ausprobieren.
              </>
            ),
            name: 'Oleg ',
            source: 'Play Store',
          },
        ]}
        children={
          <div className="w-full flex justify-center mt-8">
            <RegisterLink
              tw={'text-2xl'}
              href={`${webAppUrl}signup?companyType=business`}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                trackEvent('trackCustom', 'InitiateB2BSignup');
              }}>
              Jetzt registrieren
            </RegisterLink>
          </div>
        }
      />

      <AdvantageRow
        title="DropFriends-App & Online-Software"
        titleId="app"
        className="justify-center">
        <Advantage icon={<HiStarIcon />} title="Schnelle Registrierung"></Advantage>
        <Advantage icon={<HiStarIcon />} title="Für alle Paketdienstleister"></Advantage>
        <Advantage icon={<HiStarIcon />} title="Made in Germany | Köln"></Advantage>
        <Advantage icon={<HiStarIcon />} title="Zentrale Steuerung über Deinen Browser"></Advantage>
        <Advantage icon={<HiStarIcon />} title="Simples Handling per Smartphone-App"></Advantage>
      </AdvantageRow>

      <LPFooter />
    </PageLayout>
  );
};

export const query = graphql`
  {
    Hero1: file(relativePath: { eq: "paketshop-werden/01_hero_business.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero2: file(relativePath: { eq: "paketshop-werden/02_hero_business.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero3: file(relativePath: { eq: "paketshop-werden/03_hero_business.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero4: file(relativePath: { eq: "paketshop-werden/04_hero_business.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero5: file(relativePath: { eq: "paketshop-werden/05_hero_business.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    ImageGutschein: file(relativePath: { eq: "paketshop-werden/lp_business_gutschein.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    ImagePakete: file(relativePath: { eq: "paketshop-werden/lp_business_pakete.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    ImageKleiderstange: file(
      relativePath: { eq: "paketshop-werden/lp_business_kleiderstange.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Business3: file(relativePath: { eq: "b2b-business-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Business4: file(relativePath: { eq: "b2b-business-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Sprint3: file(relativePath: { eq: "b2b-sprint-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default ErfolgreichMitPaketshops;
