import React from 'react';
import {
  Container,
  Links,
  ListItem,
  Section,
  ListLink,
  LinkList,
  PageLink,
  Wrapper,
} from './styles';

import WideLogo from '../../svgs/WideLogo';

const LPFooter = () => {
  return (
    <Wrapper>
      <Section>
        <Container>
          <WideLogo />

          <Links>
            <LinkList>
              <ListItem>
                <PageLink to="/impressum">Impressum</PageLink>
              </ListItem>
              <ListItem>
                <ListLink href="/Datenschutzerklärung_16.03.2022.pdf" target="_blank">
                  Datenschutzerklärung
                </ListLink>
              </ListItem>
              <ListItem>
                <PageLink to="/agb">AGB</PageLink>
              </ListItem>
            </LinkList>
          </Links>
        </Container>
      </Section>
    </Wrapper>
  );
};

export default LPFooter;
